<template>
  <div>
    <table-local-v-1-widget
      :loading="loading"
      :items="items"
      :columns="cColumns"
      :actions="cActions"
      @clickView="onClickView"
    />
    <!-- <pre>{{ items }}</pre> -->
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: '',
    },
    keyTable: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      items: [],
      loading: false,
    }
  },
  computed: {
    cActions() {
      const list = [{ keyEmit: 'clickView', label: 'ดูเพิ่มเติม', iconName: 'EyesIcon' }]

      //   if (this.keyTable === 'checking') {
      //     list.push({ keyEmit: 'clickApprove', label: 'อนุมัติ', iconName: '' })
      //     list.push({ keyEmit: 'clickReject', label: 'ไม่อนุมัติ', iconName: '' })
      //   }

      return list
    },
    cColumns() {
      const list = [
        {
          label: 'วันที่ (วัน-เดือน-ปี)',
          field: 'created_at',
          formatFn: val => this.$date(val).format('MM-DD-YYYY HH:mm'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อหมวดหมู่ราศี',
          field: 'name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อร้าน',
          field: 'owner_data.name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'อีเมล',
          field: 'owner_data.email',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ]

      if (this.keyTable === 'rejected') {
        list.push({
          label: 'เหตุผลที่ไม่อนุมัติ',
          field: 'admin_note',
          tdClass: 'text-center',
          thClass: 'text-center',
        })
      }

      return list
    },
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      //   console.log('getData', this.keyTable)
      if (!this.url) return
      this.loading = true
      const resp = await this.api.get(this.url).catch(() => null)
      if (resp && Array.isArray(resp)) {
        this.items = [...resp]
      }
      this.loading = false
    },
    onClickView(data) {
      //   console.log('onClickView', data)
      this.$router.push({ name: 'zodiac-management-details', params: { id: data.id } })
    },
    // async onClickApprove(data) {
    //   const resp = await this.api.post('api/admin/recheck-zodiac/confirm-zodiac', {
    //     image_topic_zodiac_data_id: data.id,
    //     status: 'approved',
    //   })
    //   console.log('onClickApprove', resp)
    // },
  },
}
</script>

<style lang="scss" scoped></style>
